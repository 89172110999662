import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  ClassesType,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import { PrescriptionStateLabel } from '~/components/common/labels/PrescriptionStateLabel'
import {
  getIsFetchingChewyItems,
  getPrescriptionChewyItems,
  getPrescriptionError,
} from '~/store/duck/prescriptions'
import { usePatientWeight } from '~/store/hooks/patient'
import { useGetPrescriptionStateType } from '~/store/hooks/prescription'
import { getCRUDByArea, getCurrentUserId } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getPatient } from '~/store/reducers/patients'

import { PrescriptionActionExpandedContext } from '../../PrescriptionContext'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      padding: theme.spacing(1, 2),
    },
    stateLabel: {
      marginRight: 'auto',
    },
  }),
  { name: 'PrescriptionChewyCheckoutActiveRxActions' },
)

export interface PrescriptionChewyCheckoutActiveRxActionsProps {
  classes: ClassesType<typeof useStyles>
}

export const PrescriptionChewyCheckoutActiveRxActions = ({
  classes: classesProp,
}: PrescriptionChewyCheckoutActiveRxActionsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const prescriptionError = useSelector(getPrescriptionError)
  const chewyItems = useSelector(getPrescriptionChewyItems)
  const isFetchingChewyItems = useSelector(getIsFetchingChewyItems)
  const currentUserId = useSelector(getCurrentUserId)!
  const memberIsLoading = useSelector(getMemberIsLoading)

  const {
    disableDrafts,
    automaticallyCreateTaskField,
    canApproveAndSubmitChewyRx,
    isEdit,
    isLoadingOrUpdating,
    isSending,
    needsDvmLicenseEntryTooltip,
    stateId,
    patientId,
    onSave,
    isChewyActiveRxReadOnly,
  } = useContext(PrescriptionActionExpandedContext)

  const patient = useSelector(getPatient(patientId))
  const patientWeight = usePatientWeight(patientId)
  const noWeightOrDob = !patientWeight || !patient?.dateOfBirth
  const weightOrDobTooltip = noWeightOrDob
    ? t('Tooltips:PRESCRIPTION_NEED_WEIGHT_AND_DOB')
    : null
  const approveTooltip = needsDvmLicenseEntryTooltip || weightOrDobTooltip

  const { isApproved } = useGetPrescriptionStateType()(stateId)

  const isDisabled =
    (isEdit && R.isNil(chewyItems)) ||
    Boolean(prescriptionError) ||
    isFetchingChewyItems

  if (isChewyActiveRxReadOnly) {
    return null
  }

  const handleSaveActiveRx = () => {
    onSave({ isActiveRxSaveDraft: true })
  }

  const handleApproveActiveRx = () => {
    onSave({
      isActiveRxApprove: true,
      signatureInfo: {
        signerId: currentUserId,
        signature: null,
      },
    })
  }

  const disableSaveWithTask =
    disableDrafts && automaticallyCreateTaskField.value

  return (
    <Grid display="flex" flexDirection="column" gap={1}>
      {stateId && (
        <Box alignItems="center" display="flex">
          <Text strong mr={1} variant="subheading3">{`${t(
            'Common:STATUS',
          )}:`}</Text>
          <PrescriptionStateLabel
            className={classes.stateLabel}
            stateId={stateId}
          />
        </Box>
      )}
      <Grid display="flex" gap={2}>
        {!isApproved && (
          <Tooltip
            title={
              disableSaveWithTask
                ? t('Tooltips:PRESCRIPTION_DISABLED_DRAFTS_TOOLTIP')
                : ''
            }
          >
            <span>
              <ButtonWithLoader
                className={classNames(classes.button)}
                disabled={
                  !permissions.update ||
                  disableSaveWithTask ||
                  isLoadingOrUpdating ||
                  isDisabled
                }
                key="activeRxSaveDraft"
                loading={isSending}
                onClick={handleSaveActiveRx}
              >
                {t('Common:SAVE_AND_CLOSE_ACTION')}
              </ButtonWithLoader>
            </span>
          </Tooltip>
        )}
        {canApproveAndSubmitChewyRx && (
          <>
            <Tooltip placement="top" title={approveTooltip}>
              <span>
                <ButtonWithLoader
                  className={classNames(classes.button)}
                  disabled={
                    isLoadingOrUpdating || approveTooltip !== null || isDisabled
                  }
                  key="activeRxApprove"
                  loading={isSending || memberIsLoading}
                  onClick={handleApproveActiveRx}
                >
                  {t('Common:APPROVE_AND_CLOSE_ACTION')}
                </ButtonWithLoader>
              </span>
            </Tooltip>
          </>
        )}
      </Grid>
    </Grid>
  )
}
