import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  BasePuiDialogProps,
  ConsentForm,
  DocumentElement as DocumentElementPage,
  DocumentElementType,
  PaginatedDocumentInstance,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { clearResolvedDocument } from '~/store/actions/documents'
import { getResolvedDocument } from '~/store/reducers/documents'
import {
  Document as DocumentType,
  DocumentElementEdit,
  RichTextWithPlaceholderEdit,
  RichTextWithPlaceholderPreview,
} from '~/types'

function transformElement(
  element: DocumentElementEdit | RichTextWithPlaceholderPreview,
): DocumentElementPage | null {
  switch (element.type) {
    case DocumentElementType.TEXT:
      return {
        ...element,
        content:
          (element as RichTextWithPlaceholderPreview).content ||
          (element as RichTextWithPlaceholderEdit).contentWithPlaceholders,
      }
    case DocumentElementType.SINGLE_SELECT:
      return {
        ...element,
        selectedOptionId: null,
        options: [...element.options].sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber,
        ),
      }
    case DocumentElementType.MULTI_SELECT:
      return {
        ...element,
        selectedOptionIds: null,
        options: [...element.options].sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber,
        ),
      }
    default:
      return element as DocumentElementPage
  }
}

const transformDocumentElements = (
  elements: DocumentElementEdit[],
): Array<Array<DocumentElementPage>> => {
  const pages: Array<Array<DocumentElementPage>> = []
  let currentPage: Array<DocumentElementPage> = []

  elements.forEach((element) => {
    if (element.type === DocumentElementType.PAGE_BREAK) {
      // Add the current page to pages if it has elements
      if (currentPage.length > 0) {
        pages.push(currentPage)
        currentPage = []
      }
    } else {
      // Add the current element to the current page
      const transformedElement = transformElement(element)
      if (transformedElement) {
        currentPage.push(transformedElement)
      }
    }
  })

  // Add the last page if there are any remaining elements
  if (currentPage.length > 0) {
    pages.push(currentPage)
  }

  return pages
}

const transformDocument = (document: DocumentType): PaginatedDocumentInstance =>
  ({
    ...document,
    pages: transformDocumentElements(
      document?.template?.documentElements
        ? [...document.template.documentElements].sort(
            (a, b) => a.sequenceNumber - b.sequenceNumber,
          )
        : [],
    ),
  }) as unknown as PaginatedDocumentInstance

interface DocumentFormPreviewDialogProps extends BasePuiDialogProps {
  document: DocumentType
}

export const DocumentFormPreviewDialog = ({
  document,
  open,
  onClose,
}: DocumentFormPreviewDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const resolvedDocument = useSelector(getResolvedDocument)

  const form = transformDocument(resolvedDocument || document)

  useEffect(
    () => () => {
      dispatch(clearResolvedDocument())
    },
    [],
  )

  return (
    <PuiDialog
      fullWidth
      open={open}
      title={t('Common:PREVIEW_NOUN')}
      onClose={onClose}
    >
      <ConsentForm form={form} />
    </PuiDialog>
  )
}
