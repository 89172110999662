import i18n from '~/locales/i18n'

enum PaymentType {
  ACH_SALE = 'AchSale',
  ACH_REFUND = 'AchRefund',
  ACH_VOID = 'AchVoid',
  ADJUSTMENT = 'Adjustment',
  ADJUSTMENT_CREDIT = 'Adjustment credit',
  ADJUSTMENT_DEBIT = 'Adjustment debit',
  AUTH = 'Auth',
  AUTHORIZATION = 'Authorization',
  CREDIT = 'Credit',
  DEPOSIT = 'Deposit',
  GO_PAYMENT = 'GoPayment',
  GO_STRIPE_PAYMENT = 'GoStripePayment',
  INVOICE = 'Invoice',
  PAYMENT = 'Payment',
  REFUND = 'Refund',
  REVERSE_CHARGE = 'Reverse charge',
  SALE = 'Sale',
  UNDO = 'Undo',
  VOID = 'Void',
  IMPORTED = 'Imported',
  ON_ACCOUNT = 'On account',
  // NOTE: this list is not complete
}

export default PaymentType

export enum PaymentLabel {
  Applied = 'Applied',
  DebitAdjustment = 'DebitAdjustment',
  DebitImported = 'DebitImported',
  Authorized = 'Authorized',
  Unapplied = 'Unapplied',
  PartiallyApplied = 'PartiallyApplied',
  Refund = 'Refund',
  Refunded = 'Refunded',
  Void = 'Void',
  Voided = 'Voided',
  Reversed = 'Reversed',
  ReverseCharge = 'ReverseCharge',
}

export const getRawPaymentTypeMultiplier: Partial<Record<PaymentType, number>> =
  {
    [PaymentType.PAYMENT]: 1,
    [PaymentType.REVERSE_CHARGE]: -1,
    [PaymentType.CREDIT]: 1,
    [PaymentType.ADJUSTMENT]: -1,
    [PaymentType.IMPORTED]: -1,
    [PaymentType.DEPOSIT]: 1,
    [PaymentType.UNDO]: -1,
    [PaymentType.ON_ACCOUNT]: 1,
  }

export const PaymentTypesLabel: Record<string, string> = {
  [PaymentType.REVERSE_CHARGE]: i18n.t('Payments:PAYMENT_TYPES.REVERSE_CHARGE'),
  [PaymentType.PAYMENT]: i18n.t('Payments:PAYMENT_TYPES.PAYMENT'),
  [PaymentType.CREDIT]: i18n.t('Payments:PAYMENT_TYPES.CREDIT'),
  [PaymentType.DEPOSIT]: i18n.t('Payments:PAYMENT_TYPES.DEPOSIT'),
  [PaymentType.GO_PAYMENT]: i18n.t('Payments:PAYMENT_TYPES.GO_PAYMENT'),
  [PaymentType.INVOICE]: i18n.t('Payments:PAYMENT_TYPES.INVOICE'),
  [PaymentType.AUTHORIZATION]: i18n.t('Payments:PAYMENT_TYPES.AUTHORIZATION'),
  [PaymentType.ADJUSTMENT]: i18n.t('Payments:PAYMENT_TYPES.ADJUSTMENT'),
  [PaymentType.UNDO]: i18n.t('Payments:PAYMENT_TYPES.UNDO'),
  [PaymentType.GO_STRIPE_PAYMENT]: i18n.t(
    'Payments:PAYMENT_TYPES.GO_STRIPE_PAYMENT',
  ),
  [PaymentType.VOID]: i18n.t('Payments:PAYMENT_TYPES.VOID'),
  [PaymentType.REFUND]: i18n.t('Payments:PAYMENT_TYPES.REFUND_NOUN'),
  [PaymentType.ADJUSTMENT_CREDIT]: i18n.t(
    'Payments:PAYMENT_TYPES.ADJUSTMENT_CREDIT',
  ),
  [PaymentType.ADJUSTMENT_DEBIT]: i18n.t(
    'Payments:PAYMENT_TYPES.ADJUSTMENT_DEBIT',
  ),
  [PaymentType.IMPORTED]: i18n.t('Payments:PAYMENT_TYPES.IMPORTED'),
}

export enum RequestPaymentType {
  PAYMENT = 'PAYMENT',
  AUTHORIZATION = 'AUTHORIZATION',
}

export const PAYMENT_LINK_PLACEHOLDER = `<<${i18n.t(
  'Constants:PAYMENT_TYPES.DIRECT_LINK_TO_INVOICE',
)}>>`

export enum RequestPaymentWorkflow {
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  INVOICE_AUTHORIZATION = 'INVOICE_AUTHORIZATION',
  BALANCE_PAYMENT = 'BALANCE_PAYMENT',
  BALANCE_AUTHORIZATION = 'BALANCE_AUTHORIZATION',
}

export const UI_PAYMENT_LINK_PLACEHOLDERS = {
  [RequestPaymentWorkflow.INVOICE_PAYMENT]: i18n.t(
    'Constants:PAYMENT_TYPES.DIRECT_LINK_TO_INVOICE',
  ),
  [RequestPaymentWorkflow.INVOICE_AUTHORIZATION]: i18n.t(
    'Constants:PAYMENT_TYPES.DIRECT_LINK_TO_AUTHORIZE_PAYMENT',
  ),
  [RequestPaymentWorkflow.BALANCE_PAYMENT]: i18n.t(
    'Constants:PAYMENT_TYPES.DIRECT_LINK_TO_PAY_BALANCE',
  ),
  [RequestPaymentWorkflow.BALANCE_AUTHORIZATION]: i18n.t(
    'Constants:PAYMENT_TYPES.DIRECT_LINK_TO_AUTHORIZE_PAYMENT',
  ),
}

export enum MembershipPaymentStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  NEXT = 'NEXT',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  PAYMENT_DECLINE = 'PAYMENT_DECLINE',
  PROCESSING = 'PROCESSING',
  REFUND_FAILED = 'REFUND_FAILED',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUNDED = 'REFUNDED',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRES_CAPTURE = 'REQUIRES_CAPTURE',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  SUCCEEDED = 'SUCCEEDED',
  TOTAL_REFUND = 'TOTAL_REFUND',
  WRITE_OFF = 'WRITE_OFF',
}

export const MembershipPaymentStatusLabel: Record<
  MembershipPaymentStatus,
  string
> = {
  CANCELED: i18n.t('Constants:PAYMENT_TYPES.STATUS.CANCELED'),
  COMPLETED: i18n.t('Constants:PAYMENT_TYPES.STATUS.COMPLETED'),
  NEXT: i18n.t('Constants:PAYMENT_TYPES.STATUS.NEXT'),
  PARTIAL_REFUND: i18n.t('Constants:PAYMENT_TYPES.STATUS.PARTIAL_REFUND'),
  PAYMENT_DECLINE: i18n.t('Constants:PAYMENT_TYPES.STATUS.PAYMENT_DECLINE'),
  PROCESSING: i18n.t('Constants:PAYMENT_TYPES.STATUS.PROCESSING'),
  REFUND_FAILED: i18n.t('Constants:PAYMENT_TYPES.STATUS.REFUND_FAILED'),
  REFUND_PENDING: i18n.t('Constants:PAYMENT_TYPES.STATUS.REFUND_PENDING'),
  REFUNDED: i18n.t('Constants:PAYMENT_TYPES.STATUS.REFUNDED'),
  REQUIRES_ACTION: i18n.t('Constants:PAYMENT_TYPES.STATUS.REQUIRES_ACTION'),
  REQUIRES_CAPTURE: i18n.t('Constants:PAYMENT_TYPES.STATUS.REQUIRES_CAPTURE'),
  REQUIRES_CONFIRMATION: i18n.t(
    'Constants:PAYMENT_TYPES.STATUS.REQUIRES_CONFIRMATION',
  ),
  REQUIRES_PAYMENT_METHOD: i18n.t(
    'Constants:PAYMENT_TYPES.STATUS.REQUIRES_PAYMENT_METHOD',
  ),
  SUCCEEDED: i18n.t('Constants:PAYMENT_TYPES.STATUS.SUCCEEDED'),
  TOTAL_REFUND: i18n.t('Constants:PAYMENT_TYPES.STATUS.TOTAL_REFUND'),
  WRITE_OFF: i18n.t('Constants:PAYMENT_TYPES.STATUS.WRITE_OFF'),
}

export const PAYMENT_TRANSACTION_STATE = {
  AUTHORIZED: 'Authorized',
  COMPLETED: 'Completed',
  CREATED: 'Created',
  ERROR: 'Error',
  FINALIZED: 'Finalized',
  PROCESSING: 'Processing',
  UNKNOWN: 'Unknown',
  VOIDED: 'Voided',
}

export enum WpCancelPaymentType {
  REFUND = 'REFUND',
  CHARGE = 'CHARGE',
  CANCEL = 'CANCEL',
}

export enum PriceType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export const PriceTypeLabel: Record<PriceType, string> = {
  [PriceType.ANNUAL]: i18n.t('Constants:PAYMENT_TYPES.PRICE.ANNUAL'),
  [PriceType.MONTHLY]: i18n.t('Constants:PAYMENT_TYPES.PRICE.MONTHLY'),
  [PriceType.ONE_TIME]: i18n.t('Constants:PAYMENT_TYPES.PRICE.ONE_TIME'),
}

export type PaymentAdjustment = {
  notes: string
  type: string
}
