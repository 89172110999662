import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, styled } from '@mui/material'
import * as R from 'ramda'
import { ControlButtonGroupName, Text } from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import ActionsButton from '~/components/common/buttons/ActionsButton'
import { SoapForm } from '~/types'
import { PreferenceDisplayFormsData } from '~/types/entities/clientPreferences'

import { getFormSelectedOptions } from '../getFormSelectedOptions'
import FormStateLabel from '../soapV2/forms/FormStateLabel'

const PreferenceInfoRow = styled(Grid)`
  border-bottom: ${({ theme }) => theme.constants.tableBorder};
  width: 100%;
  display: flex;
  flex-direction: row;
`

const CategoryTitle = styled(Text)`
  border-right: ${({ theme }) => theme.constants.tableBorder};
  flex: 0 1 auto;
`

const FormRow = styled(Grid)`
  &:not(:last-child) {
    border-bottom: ${({ theme }) => theme.constants.tableBorder};
  }
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1)}`};
`

const FormInfo = styled(Grid)`
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  overflow: hidden;
`

export interface PreferencesDisplayFormSectionProps {
  preferences: PreferenceDisplayFormsData | undefined
}

function PreferencesDisplayFormSection({
  preferences,
}: PreferencesDisplayFormSectionProps) {
  const { t } = useTranslation('Common')

  const getActions = () => {
    const actions = [
      {
        id: ControlButtonGroupName.PREVIEW,
        label: t('Common:VIEW_ACTION'),
        Icon: EyeIcon,
        onClick: () => {}, // TODO https://chewyinc.atlassian.net/browse/CVC-7009
      },
    ]

    return R.filter(Boolean, Object.values(actions)) as PopperAction[]
  }
  if (!preferences) {
    return null
  }

  const { categoryTitle, formEntries } = preferences

  return (
    <PreferenceInfoRow container item flexWrap="nowrap">
      <CategoryTitle strong p={1}>
        {categoryTitle}
      </CategoryTitle>

      <FormInfo>
        {formEntries.map((form: SoapForm) => {
          const options = getFormSelectedOptions(form)

          return (
            <FormRow container item key={form.id}>
              <Grid item xs={8}>
                {form.name}
              </Grid>
              <Grid container item xs={3}>
                <FormStateLabel form={form} />
              </Grid>
              <Grid item xs={1}>
                <ActionsButton closeOnTooltipsEvents actions={getActions()} />
              </Grid>
              {options?.map((option) => (
                <Grid
                  container
                  item
                  alignItems="center"
                  height="40px"
                  key={option}
                  paddingLeft="16px"
                  width="100%"
                  xs={12}
                >
                  <Text noWrap title={option || ''} variant="body2">
                    {option}
                  </Text>
                </Grid>
              ))}
            </FormRow>
          )
        })}
      </FormInfo>
    </PreferenceInfoRow>
  )
}

export { PreferencesDisplayFormSection }
